/* Admin Dashboard Specific Styles */
.admin-dashboard {
    padding: 30px;
    background-color: #f4f7fa; /* Light background for contrast */
    font-family: Arial, sans-serif; /* Clean font */
}

/* Section headings */
.admin-dashboard h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    color: #2c94d5; /* Consistent with theme */
    font-size: 24px;
    font-weight: bold;
}

/* Containers for lists */
.admin-dashboard ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px; /* Space between sections */
}

/* List item styles */
.admin-dashboard li {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 15px; /* Space between each list item */
    border: 1px solid #ddd;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

/* Bold text for key details */
.admin-dashboard strong {
    font-weight: 600;
    color: #333;
}

/* Links */
.admin-dashboard a {
    color: #2c94d5;
    text-decoration: none;
    font-weight: 500;
}

.admin-dashboard a:hover {
    text-decoration: underline;
}

/* Horizontal rule (divider) */
.admin-dashboard hr {
    border-top: 1px solid #ddd;
    margin: 20px 0;
}

/* Section title separators */
.section-title {
    color: #90c349; /* Another highlight color */
    font-size: 20px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

/* Formatted video and image section */
.file-section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.file-section img,
.file-section video {
    max-width: 100px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .admin-dashboard {
        padding: 15px;
    }
    .admin-dashboard li {
        padding: 15px;
    }
    .file-section img,
    .file-section video {
        max-width: 80px;
    }
}
