.blue-text {
  color: #2c94d5; /* or the same blue color used in the footer and header */
}

.larger-text {
  font-size: 1.1em; /* adjust the font size to your liking */
}

/* Service container styling */
.service-container {
  position: relative;
  display: inline-block;
}

.service-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.service-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 148, 213, 0.5);
  color: #fff;
  padding: 20px;
  transition: opacity 0.5s, transform 0.5s;
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
}

.service-info button {
  background-color: #90c349;
  color: #fff;
  border: transparent;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.service-info button:hover {
  background-color: #0069d9;
}

.service-container:hover .service-info {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  border-radius: 10px;
}

/* Accordion styles */
.accordion-body {
  color: #2c94d5;
}

.accordion-button.collapsed {
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: #2c94d5;
}

#accordionFlushExample {
  margin-top: 30px !important;
}

/* Dropdown styles */
.navbar .dropdown-menu {
  background-color: #2c94d5;
  border: none;
  border-radius: 0.5rem;
}

.navbar .dropdown-item {
  color: white;
  padding: 10px 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar .dropdown-item:hover {
  background-color: #90c349;
  color: #fff;
}

.navbar .dropdown-item.active {
  background-color: #90c349;
  color: #fff;
}

/* ============= MEDIA QUERIES FOR RESPONSIVENESS ============= */

/* For devices smaller than 768px (Tablets and mobile) */
@media only screen and (max-width: 768px) {
  .service-image {
    width: 100%; /* Make the image responsive */
    height: auto;
  }

  .service-container {
    margin-bottom: 20px; /* Add vertical spacing between services */
  }

  .service-info {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .service-info button {
    padding: 8px 16px;
    font-size: 14px; /* Smaller button text size for mobile */
  }

  .larger-text {
    font-size: 1em; /* Adjust the font size on smaller screens */
  }

  #accordionFlushExample {
    margin-top: 20px !important;
  }
}

/* For devices smaller than 480px (Small mobile) */
@media only screen and (max-width: 480px) {
  .service-info {
    padding: 5px; /* Further reduce padding for smaller mobile screens */
  }

  .service-container {
    margin-bottom: 15px; /* Add vertical spacing between services for small screens */
  }

  .service-info button {
    padding: 6px 12px; /* Smaller button padding for small screens */
    font-size: 12px;
  }

  .accordion-body {
    font-size: 0.9em; /* Slightly smaller text for the accordion body */
  }

  .larger-text {
    font-size: 0.9em;
  }
}
