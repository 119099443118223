.dental-service .header {
    background-color: #2c94d5;
    color: white;
    padding: 2rem;
    text-align: center;
}

.dental-service .container {
    padding: 2rem;
}

.consultations-intro-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 2rem 0;
}

.consultations-intro {
    flex: 1;
}

.consultations-intro-image {
    flex: 1;
}

.consultations-intro-image img {
    width: 100%;
    max-width: 634px;
    border-radius: 10px;
}

/* Dental Services with Portrait Images */
.consultations-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.portrait-image {
    flex: 0 1 200px;
}

.consultation-img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 10px;
}

/* Portrait Layout for Consultations Section */
.consultations-portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.consultation-item {
    text-align: center;
}

.consultation-item p {
    margin-top: 10px;
    font-size: 1.1rem;
}

/* Benefits Section */
.benefits {
    background-color: #f9f9f9; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 2rem; 
    border-radius: 10px; 
    width: 80%; 
    margin: 0 auto;
}

/* Make the benefits container stack vertically */
.benefits-container {
    display: flex;
    flex-direction: column; /* Stack benefit items vertically */
    align-items: center; 
    gap: 20px; /* Keep the space between the items */
}

.benefit-item p {
    margin-top: 5px;
    font-size: 1.1rem;
}

/* ============= MEDIA QUERIES FOR RESPONSIVENESS ============= */

/* For devices smaller than 768px (Tablets and mobile) */
@media only screen and (max-width: 768px) {
    .consultations-intro-container {
        flex-direction: column; /* Stack the items vertically */
        align-items: center; /* Center the content on smaller screens */
        text-align: center; /* Center the text */
    }

    .consultations-intro,
    .consultations-intro-image {
        flex: unset; /* Remove flex-grow behavior */
        width: 100%; /* Make sure the content fills the container */
    }

    .consultations-container {
        flex-direction: column; /* Stack the portrait images and text vertically */
        align-items: center; 
        gap: 20px; /* Add space between stacked elements */
    }

    .portrait-image {
        flex: none; /* Disable flex shrinking/growing */
        width: 100%; /* Full width for portrait images */
    }

    .consultation-img {
        max-width: 100%; /* Full width for images */
    }

    .benefits {
        width: 100%; /* Full width on smaller screens */
    }

    .benefits-container {
        gap: 20px;
    }
}

/* For devices smaller than 480px (Small mobile) */
@media only screen and (max-width: 480px) {
    .dental-service .header {
        padding: 1.5rem; /* Reduce padding for smaller mobile screens */
        font-size: 1.2rem; /* Adjust the text size */
    }

    .dental-service .container {
        padding: 1rem;
    }

    .consultation-item p {
        font-size: 1rem; /* Adjust font size for small screens */
    }

    .benefit-item p {
        font-size: 1rem; /* Smaller font for mobile */
    }
}
