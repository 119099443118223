/* Style for the About Us component */
.about-us {
    font-family: Arial, sans-serif;
  }
  
  /* Style for the header with the background image */
  .about-us-header {
    background-image: url('../Images/img8.jpg'); /* Make sure this path is correct */
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
  }
  
  .about-us-header h1 {
    font-size: 3em;
    background-color: rgba(0, 0, 0, 0.5); /* Add a slight background to the text for readability */
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  /* Content section for the text and logo */
  .about-us-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
  }
  
  .text-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .text-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .text-section h2 .highlight {
    color: #2c94d5; /* Apply the custom color for 'Story' */
  }
  
  .text-section p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  
  .logo-section {
    flex: 0.5;
    display: flex;
    justify-content: center;
  }
  
  .logo-section img {
    max-width: 100%;
    height: auto;
    max-height: 150px;
  }
  
  /* Why Choose Us Section */
  .why-choose-us {
    text-align: center;
    padding: 60px 0;
    background-color: #f9f9f9;
  }
  
  .why-choose-us h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #2c94d5;
  }
  
  .reasons-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .reason-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
  }
  
  .reason-card:hover {
    transform: translateY(-10px);
  }
  
  .reason-icon {
    font-size: 2.5em;
    margin-bottom: 15px;
  }
  
  .reason-card h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .reason-card p {
    font-size: 1em;
    color: #666;
  }

  .mission-statement {
    text-align: center;
    padding: 40px;
    background-color: #eaf6ff;
    margin: 20px 0;
  }
  
  .mission-statement h2 {
    color: #2c94d5;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .mission-statement p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
  }
  .testimonials-section {
    padding: 60px 0;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .testimonials-section h2 {
    color: #2c94d5;
    margin-bottom: 40px;
  }
  
  .testimonials-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .testimonial {
    margin: 20px;
    max-width: 400px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial p {
    font-style: italic;
    color: #666;
  }
  
  .testimonial h4 {
    margin-top: 10px;
    color: #333;
    font-weight: normal;
  }

  .call-to-action {
    text-align: center;
    padding: 40px;
    background-color: #90c349;
    color: white;
  }
  
  .call-to-action h2 {
    margin-bottom: 20px;
    font-size: 2.5em;
  }
  
  .call-to-action p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: #2c94d5;
    color:white;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #fff7f0;
  }
  