.contact-us {
    max-width: 1200px;
    margin: 0 auto;
}

.map-container {
    flex: 1;
}

.working-hours {
    flex: 1;
    padding-left: 2rem;
}

.contact-icons {
    margin-top: 30px;
}

.contact-icons svg {
    margin: 0 15px;
}
