.telemedicine-form {
    max-width: 800px;
    margin: 0 auto;
}

.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.full-width {
    width: 100%;
}

.form-row input, 
.form-row textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.form-row button {
    cursor: pointer;
}

.form-row button:hover {
    background-color: #2278b4;
}

/* Icon Styling in Benefits Section */
.benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}



.telemedicine-form button {
    background-color: #2c94d5; /* Primary button color */
    color: #fff; /* Text color */
    padding: 10px 20px; /* Padding for top/bottom and left/right */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    margin-top: 10px; /* Space above the button */
    width: 100%; /* Full width for mobile-friendly design */
}

.telemedicine-form button:hover {
    background-color: #1f7f9c; /* Darker shade on hover */
}
