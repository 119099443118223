/* Top bar styling */
.top-bar {
    background-color: #2c94d5;
    padding: 0.5rem 1rem;
}

.top-bar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.opening-hours {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.opening-hours i {
    margin-right: 5px;
}

.contact-icons {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

.contact-icons a {
    color: #fff;
    font-size: 20px;
    margin-left: 15px;
   
}

.top-bar-buttons {
    display: flex;
    align-items: center;
}

.top-bar-buttons .btn {
    background-color: #90c349;
    color: #fff;
    margin-right: 10px;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.language-buttons {
    display: flex;
    align-items: center;
}

.language-buttons .btn {
    background-color: #90c349;
    color: #fff;
    margin-left: 5px;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.25rem;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .top-bar .container {
        flex-direction: column;
        align-items: flex-start;
    }

    .opening-hours, .contact-icons, .top-bar-buttons {
        margin-bottom: 10px;
    }

    .top-bar-buttons {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }

    .top-bar-buttons .btn {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .language-buttons {
        justify-content: center;
        width: 100%;
    }

    .language-buttons .btn {
        flex: 1;
        margin: 0 5px;
    }

    .opening-hours {
        display: none;
    }

    .contact-icons {
        justify-content: center;
        width: 100%;
    }

    .contact-icons a {
        margin: 0 10px;
    }
}

/* Ensure horizontal alignment on large screens */
@media (min-width: 769px) {
    .top-bar .container > * {
        flex: 0 0 auto;
    }
}

/* Existing navbar styles remain unchanged */
/* Navbar styling */
.navbar {
    background-color: #fff;
    font-weight: 500;
    padding: 0.5rem 1rem;
}

.navbar-brand img {
    width: 70px;
    height: 50px;
}

.navbar-nav .nav-link {
    color: #2996ce;
}

