/* General styles */
.soft-tissue-surgery .header {
    background-color: #2c94d5;
    color: white;
    padding: 2rem;
    text-align: center;
}

.soft-tissue-surgery .container {
    padding: 2rem;
}

/* What to Expect Section */
.consultations-intro-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 2rem 0;
}

.consultations-intro {
    flex: 1;
    color: #333;
}

.consultations-intro h2 {
    color: #2c94d5;
}

.consultations-intro p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1.1rem;
}

.consultations-intro-image {
    flex: 1;
}

.consultations-intro-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
}

/* List Styles */
.consultations-intro ul {
    margin-top: 1rem;
    padding-left: 20px;
    list-style: none; /* Remove default bullets */
}

.consultations-intro ul li {
    font-size: 1.1rem;
    padding: 0.5rem 0;
    position: relative; /* For custom bullet positioning */
}

.consultations-intro ul li:before {
    content: '•'; /* Custom bullet */
    font-size: 1.5rem;
    color: #90c349;
    position: absolute;
    left: -20px; /* Moves the bullet out */
}

/* Portrait Images Section */
.consultations-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.portrait-image {
    flex: 0 1 200px;
}

.consultation-img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 10px;
}

.consultations-portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.consultation-item {
    text-align: center;
}

.consultation-item p {
    margin-top: 10px;
    font-size: 1.1rem;
}

/* Benefits Section */
.benefits {
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
}

.benefits-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.benefit-item p {
    margin-top: 5px;
    font-size: 1.1rem;
}

/* ============= MEDIA QUERIES FOR RESPONSIVENESS ============= */

/* For devices smaller than 768px (Tablets and mobile) */
@media only screen and (max-width: 768px) {
    .consultations-intro-container {
        flex-direction: column; /* Stack the items vertically */
        align-items: center; /* Center the content on smaller screens */
        text-align: center; /* Center the text */
    }

    .consultations-intro,
    .consultations-intro-image {
        flex: unset; /* Remove flex-grow behavior */
        width: 100%; /* Make sure the content fills the container */
    }

    .consultations-container {
        flex-direction: column; /* Stack the portrait images and text vertically */
        align-items: center; 
        gap: 20px; /* Add space between stacked elements */
    }

    .portrait-image {
        flex: none; /* Disable flex shrinking/growing */
        width: 100%; /* Full width for portrait images */
    }

    .consultation-img {
        max-width: 100%; /* Full width for images */
    }

    .benefits {
        width: 100%; /* Full width on smaller screens */
    }

    .benefits-container {
        gap: 20px;
    }
}

/* For devices smaller than 480px (Small mobile) */
@media only screen and (max-width: 480px) {
    .soft-tissue-surgery .header {
        padding: 1.5rem; /* Reduce padding for smaller mobile screens */
        font-size: 1.2rem; /* Adjust the text size */
    }

    .soft-tissue-surgery .container {
        padding: 1rem;
    }

    .consultation-item p {
        font-size: 1rem; /* Adjust font size for small screens */
    }

    .benefit-item p {
        font-size: 1rem; /* Smaller font for mobile */
    }
}
